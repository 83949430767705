import { useEffect, useState } from "react";
import Portfolio from "../portfolio";
import ScrollingBanner from "../ScrollingBanner";
import Loading from "../loading";
import FadeInComponent from "../fade-in";

const isMobile = () =>
  window.innerWidth < 768 || navigator.userAgent.indexOf("Mobi") > -1;

const Main = () => {
  const [mobileView, setMobileView] = useState<boolean>(isMobile());
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    function handleResize() {
      setMobileView(isMobile());
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  if (loading) return <Loading />;
  return (
    <FadeInComponent>
      <div className="master-container">
        <header className="main-header">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "32px 40px 32px 32px",
            }}
          >
            <img
              width={140}
              height={75}
              style={{ marginTop: 8, objectFit: "contain", borderRadius: 4 }}
              src="images/logo.png"
            />

            <a
              className="etsy"
              target="_blank"
              href="https://skybabyus.etsy.com"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              <small
                style={{
                  fontSize: "11px",

                  marginBottom: -10,
                  padding: 0,
                  fontWeight: "normal",
                }}
              >
                Prints & More
              </small>
              <br />
              <div
                style={{ columnGap: 6, display: "flex", alignItems: "center" }}
              >
                Now Available on Etsy!
              </div>
            </a>
          </div>
        </header>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "flex-start",

            padding: "16px 24px",
          }}
        >
          <aside className="profile-sidebar">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <header style={{ marginBottom: 13 }}>
                <h1>Skylar Wilson</h1>
              </header>
              <div style={{ display: "flex" }}>
                <img
                  className="profile-pic"
                  src="images/profile.jpg"
                  alt="Skylar, the creator of SkySpace."
                  height={150}
                  width={150}
                  style={{ objectFit: "contain", borderRadius: 8 }}
                />

                <div
                  style={{
                    marginLeft: 8,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p className="personal-msg" style={{ marginTop: 0 }}>
                    <b> "Skybaby Cartoons"</b>
                  </p>

                  <ul>
                    <li>Male</li>
                    <li>125 years old</li>
                    <li>Nashville,</li>
                    <li>TENNESSEE</li>
                    <li>United States</li>
                    <div className="online-now">Online Now!</div>
                  </ul>

                  <ul>
                    <li>Last Login:</li>
                    <li>12/25/2079</li>
                  </ul>
                </div>
              </div>
            </div>
            <section className="myspace-url-box bg-white">
              <h3>
                <b>FOR ALL INQUIRIES:</b>
              </h3>
              <p style={{ marginLeft: -3, paddingTop: 4 }}>
                email{" "}
                <a
                  style={{ fontWeight: "600" }}
                  href="mailto:wilson.skylar@gmail.com"
                >
                  wilson.skylar@gmail.com
                </a>
              </p>
            </section>
            <section className="interests ">
              <table className="sidebar-table bg-white">
                <caption>
                  <h2 className="sidebar-table-h2">Skylar's Interests</h2>
                </caption>

                <tbody>
                  <tr>
                    <th className="sidebar-table-header" scope="row">
                      General
                    </th>
                    <td className="sidebar-table-data">
                      <p>
                        Cartoons, music, movies, junk food, history, and
                        fishing. And friends and family.
                      </p>
                    </td>
                  </tr>

                  <tr>
                    <th className="sidebar-table-header" scope="row">
                      Music
                    </th>
                    <td className="sidebar-table-data">
                      <p>
                        <b>Genres</b>: Country, rock, blues, jazz and pop.
                      </p>
                    </td>
                  </tr>

                  <tr>
                    <th className="sidebar-table-header" scope="row">
                      Movies
                    </th>
                    <td className="sidebar-table-data">
                      <p>
                        <b>Films</b>: most anything 80's/90's.
                      </p>

                      <p>
                        <b>Directors</b>: Tarantino, Spielberg, Scorcese
                      </p>
                    </td>
                  </tr>

                  <tr>
                    <th className="sidebar-table-header" scope="row">
                      Television
                    </th>
                    <td className="sidebar-table-data">
                      <p>I have a big t.v.</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>

            <section className="details">
              <table className="sidebar-table bg-white">
                <caption>
                  <h2 className="sidebar-table-h2">Skylar's Details</h2>
                </caption>

                <tbody>
                  <tr>
                    <th className="sidebar-table-header" scope="row">
                      Status:
                    </th>
                    <td className="sidebar-table-data">Awesome</td>
                  </tr>

                  <tr>
                    <th className="sidebar-table-header" scope="row">
                      Here For:
                    </th>
                    <td className="sidebar-table-data">
                      Serious Relationships
                    </td>
                  </tr>

                  <tr>
                    <th className="sidebar-table-header" scope="row">
                      Hometown:
                    </th>
                    <td className="sidebar-table-data">Memphis, TN</td>
                  </tr>

                  <tr>
                    <th className="sidebar-table-header" scope="row">
                      Ethnicity:
                    </th>
                    <td className="sidebar-table-data">White/Caucasion</td>
                  </tr>

                  <tr>
                    <th className="sidebar-table-header" scope="row">
                      Zodiac Sign:
                    </th>
                    <td className="sidebar-table-data">Capricorn</td>
                  </tr>

                  <tr>
                    <th className="sidebar-table-header" scope="row">
                      Smoke / Drink:
                    </th>
                    <td className="sidebar-table-data">Do it</td>
                  </tr>

                  <tr>
                    <th className="sidebar-table-header" scope="row">
                      Occupation:
                    </th>
                    <td className="sidebar-table-data">Cartoonist</td>
                  </tr>
                </tbody>
              </table>
            </section>
          </aside>
          <main>
            <section className="user-network">
              <header>
                <h2 style={{ fontWeight: "bold" }}>
                  Skylar is in your extended network
                </h2>
              </header>
            </section>
            <Portfolio isMobile={false} />
            <section className="blurbs bg-white" style={{ paddingBottom: 2 }}>
              <header
                className="main-section-header"
                style={{ borderTopLeftRadius: 4, borderTopRightRadius: 4 }}
              >
                <h2 className="main-section-h2" style={{ padding: "6px 0" }}>
                  Skylar's Blurbs
                </h2>
              </header>

              <h3 style={{ marginTop: 12 }}>About me:</h3>
              <p style={{ fontSize: 12, lineHeight: 1.5, marginTop: 8 }}>
                Hi! I'm Skylar Wilson from Nashville, TN. I am the capitan of
                Skybaby Cartoons and we specialize in cartoons and 2D
                animations. I've been lucky enough to work with some of the most
                talented music and visual artists in Nashville and beyond. I've
                been a finalist in several animation festivals, and was lucky to
                be a part of a Peabody Award-winning short! That was real cool.
              </p>
            </section>

            <section
              className="friends bg-white"
              style={{
                marginTop: 12,
                borderBottomLeftRadius: 4,
                borderBottomRightRadius: 4,
              }}
            >
              <header
                className="main-section-header"
                style={{ borderTopLeftRadius: 4, borderTopRightRadius: 4 }}
              >
                <h2 className="main-section-h2" style={{ padding: "6px 0" }}>
                  Skylar's Client Space
                </h2>
              </header>

              <p>
                <b>
                  Skylar has{" "}
                  <span className="focus-highlight">some recent</span> clients.
                </b>
              </p>

              <div
                className="friend-pic-container"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-evenly",
                  rowGap: 24,
                  padding: 12,
                }}
              >
                <div>
                  <figcaption style={{ marginBottom: 8 }}>
                    <a href="#">Netflix</a>
                  </figcaption>
                  <img
                    src="images/netflix.png"
                    alt=""
                    width={100}
                    style={{ borderRadius: 12 }}
                  />
                </div>

                <div>
                  <figcaption style={{ marginBottom: 8 }}>
                    <a href="#">CMT</a>
                  </figcaption>
                  <img
                    src="images/cmt.png"
                    alt=""
                    style={{
                      marginTop: 8,
                      objectFit: "contain",
                      borderRadius: 4,
                    }}
                    width={100}
                  />
                </div>

                <div>
                  <figcaption style={{ marginBottom: 8 }}>
                    <a href="#">Warner Bros.</a>
                  </figcaption>
                  <img
                    src="images/warner_bros.png"
                    alt=""
                    style={{
                      marginTop: 8,
                      objectFit: "contain",
                      borderRadius: 4,
                    }}
                    width={100}
                  />
                </div>

                <div>
                  <figcaption style={{ marginBottom: 8 }}>
                    <a href="#">Dierks Bentley</a>
                  </figcaption>
                  <img
                    src="images/dierks.jpeg"
                    alt=""
                    width={100}
                    style={{
                      marginTop: 8,
                      objectFit: "contain",
                      borderRadius: 4,
                    }}
                  />
                </div>

                <div>
                  <figcaption style={{ marginBottom: 8 }}>
                    <a href="#">Midland</a>
                  </figcaption>
                  <img
                    src="images/midland.webp"
                    alt=""
                    style={{
                      marginTop: 8,
                      objectFit: "contain",
                      borderRadius: 4,
                    }}
                    width={100}
                  />
                </div>

                <div>
                  <figcaption style={{ marginBottom: 8 }}>
                    <a href="#">Fancy Hagood</a>
                  </figcaption>
                  <img
                    src="images/fancy.webp"
                    alt=""
                    width={100}
                    style={{
                      marginTop: 8,
                      objectFit: "contain",
                      borderRadius: 4,
                    }}
                  />
                </div>

                <div>
                  <figcaption style={{ marginBottom: 8 }}>
                    <a href="#">Kacey Musgraves</a>
                  </figcaption>
                  <img
                    src="images/kacey.webp"
                    width={100}
                    style={{
                      marginTop: 8,
                      objectFit: "contain",
                      borderRadius: 4,
                    }}
                  />
                </div>

                <div>
                  <figcaption style={{ marginBottom: 8 }}>
                    <a href="#">Old Crow Medicine Show</a>
                  </figcaption>
                  <img
                    src="images/old_crow.jpeg"
                    alt=""
                    width={100}
                    style={{
                      marginTop: 8,
                      objectFit: "contain",
                      borderRadius: 4,
                    }}
                  />
                </div>
              </div>

              <p className="friends-list-link">
                {/* <a href="#">View All of Skylar's Friends</a> */}
              </p>
            </section>
          </main>
        </div>
        <footer className="footer">
          <small>
            ©{new Date().getFullYear()} skybabycartoons.com. All Rights
            Reserved.
          </small>
        </footer>
      </div>
    </FadeInComponent>
  );
};

export default Main;
