import React, { useState, useEffect, PropsWithChildren } from "react";
import "./FadeIn.css"; // Import the CSS file for fade-in effect

const FadeInComponent: React.FC<PropsWithChildren> = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true); // Trigger fade-in when the component is mounted
  }, []);

  return (
    <div className={`fade-in ${isVisible ? "visible" : ""}`}>{children}</div>
  );
};

export default FadeInComponent;
