import React from 'react';
import logo from './logo.svg';
import './App.css';
import Main from './components/main';

function App() {
  return (
    <Main/>
  );
}

export default App;
